<div class="layout-container" [ngClass]="containerClass">
  <app-topbar></app-topbar>
  <app-sidebar></app-sidebar>

  <div class="layout-content-wrapper">
    <div class="layout-content">
      <div class="mx-5"><!-- layout-content-inner-->
        <router-outlet></router-outlet>

        <app-footer></app-footer>
      </div>
    </div>
  </div>
  <!--  <app-config></app-config>-->
</div>
