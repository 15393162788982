import { JobCenter } from './../core/models/employee.interface';
import { UserService } from './user.service';



import {Injectable, OnDestroy, OnInit} from '@angular/core';
import {Observable} from "rxjs";
import {HttpClient, HttpParams} from "@angular/common/http";

import {FormGroup} from "@angular/forms";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class JobCenterService {

  private baseUrl: string = environment.baseUrl

  constructor(private http: HttpClient,

    private UserService:UserService) { }

    getJobCenters(filters:any){

      let params = new HttpParams();

   //   params = params.append('job_center', this.UserService.JobCenter);

      if(filters.search){
          params =  params.append('search',  filters.search)
      }

      if(filters.company){
        params =  params.append('company',  filters.company)
    }

      return this.http.get<JobCenter[]>(`${this.baseUrl}/job-centers/`, {params})
  }
  deleteJobCenter(idItem:string){
    return this.http.delete<any>(`${this.baseUrl}/job-centers/${idItem}/`)
}

saveJobCenter(data:any){
  return this.http.post<any>(`${this.baseUrl}/job-centers/`,data)
}

patchJobCenter(data:any,id:string){
  return this.http.patch<any>(`${this.baseUrl}/job-centers/${id}/`,data)
}

updateSettingModule(data:any){
  return this.http.put<any>(`${this.baseUrl}/configuration-modules/${data.id}/`,data)
}

saveSettingModule(data:any){
  return this.http.post<any>(`${this.baseUrl}/configuration-modules/${data.id}`,data)
}

getSettingModule(){
  let params = new HttpParams();
  return this.http.get<any>(`${this.baseUrl}/configuration-modules/`, {params})
}


}
