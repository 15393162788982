<div class="card border-left">
  <div class="col-12 md:col-12 d-flex justify-content-between flex-wrap mb-3">
    <span class=" h4 fw-bold text-primary p-2"><i class="fas fa-calendar me-2"></i>CALENDARIO</span>

    <div class="me-3">
      <p-button (click)="resetFilters()" type="button" icon="fa-solid fa-filter-circle-xmark"
        styleClass="p-button-rounded p-button-danger p-button-sm ml-2">
      </p-button>

      <p-button *appHasPermission="permissionsService.PERMISSIONS_ADD.EVENTS" (click)="addEvent()" type="button"
        icon="fa-solid fa-plus" styleClass="p-button-rounded ms-2">
      </p-button>
    </div>
  </div>

  <p-accordion>
    <p-accordionTab styleClass="custom-accordion-tab" header="Filtrar calendario">
      <form [formGroup]="FormGroup" class="row pt-4 pb-4">
        <div class="col-xs-12 col-md-12 col-lg-4 mt-3">
          <app-select (onSelect)="filterByGroup($event)" [Catalog]="Catalog.GROUPS" field="name" [isDropdown]="true"
            type="p-autocomplete-administrative" [isMultiple]="false" label="Grupo ruta"
            formControlName="group"></app-select>
        </div>

        <div class="col-xs-12 col-md-12 col-lg-4 mt-3">
          <app-select [Catalog]="Catalog.EVENT_TYPES" (onSelect)="filterByGroup($event)" field="name"
            [isDropdown]="true" type="p-autocomplete" [isMultiple]="false" label="Tipo evento"
            formControlName="event_type"></app-select>
        </div>

        <div class="col-xs-12 col-md-12 col-lg-4 mt-3">
          <app-select [Catalog]="Catalog.EMPLOYEES" (onSelect)="filterByGroup($event)" field="name" [isDropdown]="true"
            type="p-autocomplete-administrative" [isMultiple]="false" label="Técnico"
            formControlName="employee"></app-select>
        </div>

        <div class="col-xs-12 col-md-12 col-lg-4 mt-4">
          <app-select (onSelect)="filterByGroup($event)" [isMain]="false" [UrlCatalog]="URL_CATALOGS.CUSTOMERS_DROPDOWN"
            field="name" [isDropdown]="false" type="select-generic" [isMultiple]="false" label="Filtrar por cliente"
            formControlName="customer">
          </app-select>
        </div>

        <div class="col-xs-12 col-md-12 col-lg-4 mt-4">
          <app-select [Catalog]="Catalog.SERVICE_TYPE" (onSelect)="filterByGroup($event)" field="name"
            [isDropdown]="true" type="p-autocomplete" [isMultiple]="false" label="Tipo servicio"
            formControlName="service_type"></app-select>
        </div>

        <div class="col-xs-12 col-md-12 col-lg-4 mt-4">
          <app-select [Catalog]="Catalog.QUOTES" (onSelect)="filterByGroup($event)" field="folio" [isDropdown]="true"
            type="p-autocomplete-administrative" [isMultiple]="false" label="Cotización"
            formControlName="quote"></app-select>
        </div>
      </form>
    </p-accordionTab>
  </p-accordion>

 
  <p-tabView [activeIndex]="index" (onChange)="handleChange($event)">
    <p-tabPanel>
      <ng-template pTemplate="header">
        <i class="pi pi-calendar me-2"></i>
        <span>Calendario</span>
      </ng-template>

      <app-calendar *ngIf="index == 0" initialView="timeGridWeek"></app-calendar>
    </p-tabPanel>
  </p-tabView>
</div>

<div class="card border-left">
  <div class="d-flex justify-content-end mb-3">
    <p-button pTooltip="Borrar filtros" (click)="resetTable()" type="button" icon="fa-solid fa-filter-circle-xmark"
      styleClass="p-button-rounded p-button-danger p-button-sm ml-2">
    </p-button>

    <p-button pTooltip="Exportar excel" (click)="exportExcel()" type="button" icon="fa fa-file-excel-o"
      styleClass="p-button-rounded p-button-success p-button-sm ml-2">
    </p-button>
  </div>

  <div [formGroup]="dataForm" class="custom-table-container">
    <p-table class="custom-table" styleClass="p-datatable-sm" #dt1 [value]="events" dataKey="id" sortMode="multiple"
      [rows]="10" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10, 25, 50]" [loading]="false"
      [paginator]="false" [globalFilterFields]="['name']" [tableStyle]="{ 'min-width': '150rem' }">

      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="folio" class="bg-primary border-bottom">
            # Folio <p-sortIcon field="folio"></p-sortIcon>
          </th>

          <th pSortableColumn="title" class="bg-primary">
            # Nombre
            <p-sortIcon field="title"></p-sortIcon>
          </th>

          <th pSortableColumn="name" class="bg-primary">
            Fecha de creación
            <p-sortIcon field="name"></p-sortIcon>
          </th>

          <th pSortableColumn="phone" class="bg-primary ">
            Fecha de servicio
            <p-sortIcon field="phone"></p-sortIcon>
          </th>

          <th pSortableColumn="employee" class="bg-primary ">
            Empleado
            <p-sortIcon field="employee"></p-sortIcon>
          </th>

          <th pSortableColumn="employee" class="bg-primary ">
            Cliente
            <p-sortIcon field="employee"></p-sortIcon>
          </th>

          <th pSortableColumn="employee" class="bg-primary ">
            Teléfono
            <p-sortIcon field="employee"></p-sortIcon>
          </th>

          <th pSortableColumn="employee" class="bg-primary ">
            Dirección
            <p-sortIcon field="employee"></p-sortIcon>
          </th>

          <th pSortableColumn="employee" class="bg-primary ">
            Plaga
            <p-sortIcon field="employee"></p-sortIcon>
          </th>

          <!-- 
          <th pSortableColumn="employee" class="bg-primary ">
            Fuente de origen
            <p-sortIcon field="employee"></p-sortIcon>
          </th>-->

          <th pSortableColumn="employee" class="bg-primary ">
            Monto
            <p-sortIcon field="employee"></p-sortIcon>
          </th>

          <th class="bg-primary " pSortableColumn="is_active">
            Estatus<p-sortIcon field="is_active"></p-sortIcon>
          </th>
        </tr>

        <tr>
          <th>
            <input pInputText type="text" (input)="onFilterInput($event,'search')" placeholder="Folio" class="w-full">
          </th>

          <th>
            <input pInputText type="text" (input)="onFilterInput($event,'search')" placeholder="Nombre" class="w-full">
          </th>

          <th></th>

          <th>
            <span class="w-100 p-fluid p-input-icon-left p-float-label">
              <p-calendar formControlName="range_date" selectionMode="range" [readonlyInput]="true" appendTo="body"
                (onSelect)="searchDate()"></p-calendar>
            </span>
          </th>

          <th>
            <app-select [Catalog]="Catalog.EMPLOYEES" (onSelect)="onFilterInput($event, 'employee')" field="name"
              [isDropdown]="true" type="p-autocomplete-administrative" [isMultiple]="false" label=""
              formControlName="employee"></app-select>
          </th>

          <th>
            <app-select (onSelect)="onFilterInput($event,'customer')"  [isMain]="false" [UrlCatalog]="URL_CATALOGS.CUSTOMERS_DROPDOWN"
            field="name" [isDropdown]="false" type="select-generic" [isMultiple]="false" label="Filtrar por cliente"
            formControlName="customer">
          </app-select>
          </th>
          <th></th>
          <th></th>
          <th></th>

          <th>
            <app-select [Catalog]="Catalog.PLAGUES" (onSelect)="onFilterInput($event, 'plague')" field="name"
              [isDropdown]="true" type="p-autocomplete" [isMultiple]="false" label=""
              formControlName="plague"></app-select>
          </th>

          <th></th>

          <th>
            <p-autoComplete [dropdown]="true" [suggestions]="filteredStatus" (completeMethod)="filterStatus($event)"
              (onSelect)="onFilterInput($event, 'status')" />
          </th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-item>
        <tr class="text-sm">
          <td class=" ">
            <div class="dropdow d">
              <p class="fw-bold  dropdown-toggle text-primary" type="button" data-bs-toggle="dropdown"
                aria-expanded="false">
                {{item.folio}}
              </p>

              <ul class="dropdown-menu" *ngIf="getStatusForMenu(item.status.key_string) === 'created'">
                <li *appHasPermission="permissionsService.PERMISSIONS_ADD.EVENTS">
                  <a (click)="editEvent(item)" class="dropdown-item hov-pointer fw-bold color-gray">
                    <i class="fas fa-edit me-2"></i>Editar
                  </a>
                </li>

                <li *appHasPermission="permissionsService.PERMISSIONS_ADD.EVENTS">
                  <a (click)="editEvent(item)" class="dropdown-item hov-pointer fw-bold color-green">
                    <i class="fab fa-whatsapp me-2"></i>Enviar indicaciones por WhatsApp
                  </a>
                </li>

                <li *appHasPermission="permissionsService.PERMISSIONS_ADD.EVENTS">
                  <a (click)="editEvent(item)" class="dropdown-item hov-pointer fw-bold color-green">
                    <i class="fas fa-bell me-2"></i>Enviar recordatorio por WhatsApp
                  </a>
                </li>

                <li *appHasPermission="permissionsService.PERMISSIONS_ADD.EVENTS">
                  <a (click)="editEvent(item)" class="dropdown-item hov-pointer fw-bold color-green">
                    <i class="fas fa-check me-2"></i>Confirmar
                  </a>
                </li>

                <li *appHasPermission="permissionsService.PERMISSIONS_ADD.EVENTS">
                  <a (click)="editEvent(item)" class="dropdown-item hov-pointer fw-bold color-red">
                    <i class="fas fa-file-pdf me-2"></i>Plantilla
                  </a>
                </li>

                <li *appHasPermission="permissionsService.PERMISSIONS_ADD.EVENTS">
                  <a (click)="editEvent(item)" class="dropdown-item hov-pointer fw-bold color-blue">
                    <i class="fas fa-money-bill me-2"></i>Cobrar
                  </a>
                </li>

                <li *appHasPermission="permissionsService.PERMISSIONS_ADD.EVENTS">
                  <a (click)="cancelEvent(item)" class="dropdown-item hov-pointer fw-bold color-red">
                    <i class="fas fa-ban me-2"></i>Cancelar
                  </a>
                </li>

                <li *appHasPermission="permissionsService.PERMISSIONS_ADD.EVENTS">
                  <a (click)="editEvent(item)" class="dropdown-item hov-pointer fw-bold color-blue">
                    <i class="fas fa-file me-2"></i>Facturar
                  </a>
                </li>

                <!--
                <li *appHasPermission="permissionsService.PERMISSIONS_ADD.EVENTS">
                  <a (click)="deleteEvent(item)" class="dropdown-item hov-pointer text-danger fw-bold">
                    <i class="fas fa-trash-alt me-2"></i>Eliminar
                  </a>
                </li>

                <li *appHasPermission="permissionsService.PERMISSIONS_READ.MIP">
                  <a (click)="CrudMipForms(item)" class="dropdown-item hov-pointer text-blue-700 fw-bold">
                    <i class="fas fa-people-roof me-2"></i>
                    Ver formularios
                  </a>
                </li>

                <li>
                  <a *ngIf="item.status.key_string == 'event-completed'" (click)="openDocuments(item)"
                    class="dropdown-item hov-pointer text-red-600 fw-bold">
                    <i class="fas fa-file me-2"></i>Documentación
                  </a>
                </li> -->
              </ul>

              <ul class="dropdown-menu" *ngIf="getStatusForMenu(item.status.key_string) === 'completed'">
                <li *appHasPermission="permissionsService.PERMISSIONS_ADD.EVENTS">
                  <a (click)="editEvent(item)" class="dropdown-item hov-pointer fw-bold color-gray">
                    <i class="fas fa-edit me-2"></i>Editar
                  </a>
                </li>

                <li *appHasPermission="permissionsService.PERMISSIONS_ADD.EVENTS">
                  <a (click)="editEvent(item)" class="dropdown-item hov-pointer fw-bold color-gray">
                    <i class="fas fa-eye me-2"></i>Ver
                  </a>
                </li>

                <li *appHasPermission="permissionsService.PERMISSIONS_ADD.EVENTS">
                  <a (click)="editEvent(item)" class="dropdown-item hov-pointer fw-bold color-red">
                    <i class="fas fa-file-pdf me-2"></i>Certificado de servicio
                  </a>
                </li>

                <li *appHasPermission="permissionsService.PERMISSIONS_ADD.EVENTS">
                  <a (click)="editEvent(item)" class="dropdown-item hov-pointer fw-bold color-gray">
                    <i class="fas fa-envelope me-2"></i>Enviar certificado
                  </a>
                </li>

                <li *appHasPermission="permissionsService.PERMISSIONS_ADD.EVENTS">
                  <a (click)="editEvent(item)" class="dropdown-item hov-pointer fw-bold color-green">
                    <i class="fab fa-whatsapp me-2"></i>Enviar certificado
                  </a>
                </li>

                <li *appHasPermission="permissionsService.PERMISSIONS_ADD.EVENTS">
                  <a (click)="editEvent(item)" class="dropdown-item hov-pointer fw-bold color-blue">
                    <i class="fas fa-signature me-2"></i>Solicitar firma
                  </a>
                </li>

                <li *appHasPermission="permissionsService.PERMISSIONS_ADD.EVENTS">
                  <a (click)="editEvent(item)" class="dropdown-item hov-pointer fw-bold color-green">
                    <i class="fas fa-star me-2"></i>Evaluar
                  </a>
                </li>

                <li *appHasPermission="permissionsService.PERMISSIONS_ADD.EVENTS">
                  <a (click)="editEvent(item)" class="dropdown-item hov-pointer fw-bold color-yellow">
                    <i class="fas fa-triangle-exclamation me-2"></i>Garantía
                  </a>
                </li>

                <li *appHasPermission="permissionsService.PERMISSIONS_ADD.EVENTS">
                  <a (click)="editEvent(item)" class="dropdown-item hov-pointer fw-bold color-gray">
                    <i class="fas fa-arrow-right me-2"></i>Seguimiento
                  </a>
                </li>

                <li *appHasPermission="permissionsService.PERMISSIONS_ADD.EVENTS">
                  <a (click)="editEvent(item)" class="dropdown-item hov-pointer fw-bold color-red">
                    <i class="fas fa-file-pdf me-2"></i>Orden de servicio
                  </a>
                </li>

                <li *appHasPermission="permissionsService.PERMISSIONS_ADD.EVENTS">
                  <a (click)="editEvent(item)" class="dropdown-item hov-pointer fw-bold color-red">
                    <i class="fas fa-file-pdf me-2"></i>Plantilla
                  </a>
                </li>

                <li *appHasPermission="permissionsService.PERMISSIONS_ADD.EVENTS">
                  <a (click)="editEvent(item)" class="dropdown-item hov-pointer fw-bold color-blue">
                    <i class="fas fa-money-bill me-2"></i>Editar pago
                  </a>
                </li>

                <li *appHasPermission="permissionsService.PERMISSIONS_ADD.EVENTS">
                  <a (click)="editEvent(item)" class="dropdown-item hov-pointer fw-bold color-blue">
                    <i class="fas fa-file me-2"></i>Facturar
                  </a>
                </li>
              </ul>

              <ul class="dropdown-menu" *ngIf="getStatusForMenu(item.status.key_string) === 'cancelled'">
                <li *appHasPermission="permissionsService.PERMISSIONS_ADD.EVENTS">
                  <a (click)="reactivateEvent(item)" *ngIf="item.status.name == 'Cancelado'"
                    class="dropdown-item hov-pointer fw-bold color-green">
                    <i class="fas fa-check me-2"></i>Recuperar evento
                  </a>
                </li>
                
                <li>
                  <a
                    *ngIf="item.status.key_string != 'event-completed'"
       
                    class="dropdown-item hov-pointer text-gray-600 fw-bold"
                  >
                    <i class="fas fa-pause me-2"></i>Posponer evento
                  </a>
                </li>
              </ul>
            </div>
          </td>

          <td>{{ item.title }}</td>
          <td>{{ item.created_at | date: 'dd/MM/yyyy hh:mm'}}</td>
          <td>{{ item.initial_date | date: 'dd/MM/yyyy' }} {{item.initial_hour }}</td>
          <td>{{ item.employee[0].name }}</td>

          <td>{{item?.customer?.name}}</td>
          <td>{{item?.customer?.contact_phone}}</td>
          <td> {{item?.customer?.address}} </td>
          <td><span *ngFor="let plague of item.plague">{{plague.name }},</span> </td>
   <!--         <td></td>-->
          <td>{{item?.total}}</td>


          <td>
            {{ item.customer.phone | phoneNumber }}
          </td>

          <td>
            {{ item.customer.address }}
          </td>

          <td>
            <span *ngFor="let plague of item.plague">
              {{ plague.name }} <br>
            </span>
          </td>

          <td>
            {{ item.total | currency }}
          </td>

          <td>
            <p-tag [severity]="getEventStatusTag(item.status.key_string)" [value]="item.status.name"></p-tag>
          </td>
        </tr>
      </ng-template>

      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="15">no hay datos.</td>
        </tr>
      </ng-template>
    </p-table>

    <p-paginator [first]="dataForm.value.first" [rows]="dataForm.value.rows" [rowsPerPageOptions]="[10, 20, 30, 50]"
      [totalRecords]="dataForm.value.totalRecords" (onPageChange)="onPageChange($event)"
      currentPageReportTemplate="{currentPage} de {totalPages}" [showJumpToPageDropdown]="true" [showPageLinks]="true"
      [showJumpToPageInput]="true"></p-paginator>
  </div>
</div>