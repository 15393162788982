import { UserService } from './user.service';



import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { DataCustomer } from '../core/models/customer.interface';
import { LocalStorageService } from './local-storage-service';

@Injectable({
  providedIn: 'root'
})
export class QuotesService {
  private baseUrl: string = environment.baseUrl

  constructor(
    private http: HttpClient,
    private UserService: UserService,
    private localStorageService: LocalStorageService
  ) { }

  getQuotes(filters: any, idCustomer: string) {
    let params = new HttpParams();

    params = params.append('job_center', this.UserService.JobCenter);
    params = params.append('paginate', true);

    if (filters.search) {
      params = params.append('search', filters.search)
    }

    if (filters.initial_date) {
      params = params.append('initial_date', filters.initial_date)
    }

    if (filters.final_date) {
      params = params.append('final_date', filters.final_date)
    }

    if (filters.employee) {
      params = params.append('employee', filters.employee)
    }

    if (filters.customer) {
      params = params.append('customer', filters.customer)
    }

    if (filters.folio) {
      params = params.append('folio', filters.folio)
    }

    if (filters.service_type) {
      params = params.append('service_type', filters.service_type)
    }

    if (filters.page) {
      params = params.append('page', filters.page)
    }

    if (filters.page_size) {
      params = params.append('page_size', filters.page_size)
    }

    return this.http.get<DataCustomer>(`${this.baseUrl}/quotes/`, { params })
  }

  getViewQuoteById(id: string) {
    return this.http.get<any>(`${this.baseUrl}/quotes/${id}/pdf/`)
  }

  aproveQuote(id: string) {
    return this.http.patch<any>(`${this.baseUrl}/quotes/${id}/approved/`, {});
  }

  addQuote(data: any) {
    return this.http.post<any>(`${this.baseUrl}/quotes/`, data);
  }

  udpdateQuote(data: any) {
    return this.http.put<any>(`${this.baseUrl}/quotes/${data.id}/`, data);
  }

  sendQuote(data: any) {
    return this.http.post<any>(`${this.baseUrl}/quotes/${data.quoteId}/send_mail/`, data);
  }

  sendQuoteWhats(data: any) {
    return this.http.post<any>(`${this.baseUrl}/quotes/${data.quoteId}/send_whatsapp/`, data);
  }

  getQuoteInfoById(idQuote: string) {
    return this.http.get<any>(`${this.baseUrl}/quotes/${idQuote}/`)
  }

  cancelQuote(id: string) {
    return this.http.patch<any>(`${this.baseUrl}/quotes/${id}/cancelled/`, {});
  }

  deleteQuote(id: string) {
    return this.http.delete<any>(`${this.baseUrl}/quotes/${id}/`)
  }

  deleteFolloUpQuote(idFollow: string) {
    return this.http.delete<any>(`${this.baseUrl}/quotes-tracing/${idFollow}/`)
  }

  getQuotesTracingByQuoteId(idQuote: string) {
    let params = new HttpParams();

    params = params.append('job_center', this.UserService.JobCenter);
    params = params.append('quote', idQuote);

    return this.http.get<any>(`${this.baseUrl}/quotes-tracing/`, { params })
  }

  getUsersPortalByCustomer(filters: any, idCustomer: string) {
    let params = new HttpParams();

    params = params.append('job_center', this.UserService.JobCenter);
    params = params.append('customer', idCustomer);

    if (filters.is_main) {
      params = params.append('is_main', filters.is_main)
    }

    if (filters.search) {
      params = params.append('search', filters.search)
    }

    if (filters.page) {
      params = params.append('page', filters.page)
    }

    if (filters.page_size) {
      params = params.append('page_size', filters.page_size)
    }

    return this.http.get<DataCustomer>(`${this.baseUrl}/customers-portal-accounts/`, { params })
  }

  getDocumentesByCustomer(idCustomer: string) {
    let params = new HttpParams();

    params = params.append('job_center', this.UserService.JobCenter);
    params = params.append('customer', idCustomer);

    return this.http.get<any>(`${this.baseUrl}/customer-documents/`, { params })
  }

  deleteUserPortalCustomer(idItem: string) {
    return this.http.delete<any>(`${this.baseUrl}/customers-portal-accounts/${idItem}/`)
  }

  getCalendarByCustomer(idItem: string) {
    return this.http.get<any>(`${this.baseUrl}/customers/${idItem}/pdf_calendar`)
  }

  saveCustomer(data: any) {
    return this.http.post<any>(`${this.baseUrl}/customers/`, data);
  }

  changePasswordUserPortal(data: any) {
    return this.http.post<any>(`${this.baseUrl}/customers-portal-accounts/${data.id}/`, data);
  }

  sendCredentials(data: any) {
    return this.http.post<any>(`${this.baseUrl}/customers-portal-accounts/${data.id}/send_credentials`, data);
  }

  saveUserPortalCustomer(data: any) {
    return this.http.post<any>(`${this.baseUrl}/customers-portal-accounts/`, data);
  }

  changePasswordPortalCustomer(data: any) {
    return this.http.post<any>(`${this.baseUrl}/customers-portal-accounts/`, data);
  }

  saveDocumentCustomer(data: any) {
    return this.http.post<any>(`${this.baseUrl}/customer-documents/${data.id}`, data);
  }

  patchDocumentCustomer(data: any, idDocument: string) {
    return this.http.patch<any>(`${this.baseUrl}/customer-documents/${idDocument}/`, data);
  }

  putCustomer(data: any) {
    return this.http.put<any>(`${this.baseUrl}/customers/${data.id}`, data);
  }

  putUserPortalCustomer(data: any) {
    return this.http.put<any>(`${this.baseUrl}/customers-portal-accounts/${data.id}`, data);
  }

  deleteDocumentCustomer(idItem: string) {
    return this.http.delete<any>(`${this.baseUrl}/customer-documents/${idItem}/`)
  }

  getZonesByCheckPoint(filters: any, idCustomer: string) {
    let params = new HttpParams();

    params = params.append('job_center', this.UserService.JobCenter);
    params = params.append('customer', idCustomer);

    if (filters.search) {
      params = params.append('search', filters.search)
    }

    if (filters.indication) {
      params = params.append('indication', filters.indication)
    }

    if (filters.page) {
      params = params.append('page', filters.page)
    }

    if (filters.page_size) {
      params = params.append('page_size', filters.page_size)
    }

    return this.http.get<any>(`${this.baseUrl}/check-points/zones/`, { params })
  }
}
