import { JobCenter } from './../core/models/employee.interface';
import { UserService } from './user.service';



import {Injectable, OnDestroy, OnInit} from '@angular/core';
import {Observable} from "rxjs";
import {HttpClient, HttpParams} from "@angular/common/http";

import {FormGroup} from "@angular/forms";
import { LocalStorageService } from './local-storage-service';
import { environment } from 'src/environments/environment';
import { DataCustomer } from '../core/models/customer.interface';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  private baseUrl: string = environment.baseUrl

  constructor(private http: HttpClient,

    private UserService:UserService,
              private localStorageService: LocalStorageService) { }
              getUsersPortalByCustomer(filters:any,idCustomer:string){

                let params = new HttpParams();

                params = params.append('job_center', this.UserService.JobCenter);
                params = params.append('customer', idCustomer);

                    if(filters.is_main){
                      params =  params.append('is_main',  filters.is_main)
                  }

                if(filters.search){
                    params =  params.append('search',  filters.search)
                }


                  if(filters.page){
                    params = params.append('page', filters.page)
                  }
                  if(filters.page_size){
                    params = params.append('page_size', filters.page_size)
                  }




                return this.http.get<DataCustomer>(`${this.baseUrl}/customers-portal-accounts/`, {params})
            }
            getCustomerByI(idCustomer:string){

          




              return this.http.get<DataCustomer>(`${this.baseUrl}/customers/${idCustomer}`)
          }
              getCustomers(filters:any,idCustomer:string){

                let params = new HttpParams();

                params = params.append('job_center', this.UserService.JobCenter);

                    if(filters.is_main){
                      params =  params.append('is_main',  filters.is_main)
                  }


                  if(idCustomer){
                    params =  params.append('main_customer',  idCustomer)
                }

                if(filters.search){

                    params =  params.append('search',  filters.search)
                }
                if(filters.initial_date){
                  params = params.append('initial_date', filters.initial_date)
                }
                if(filters.business_activity){
                  params = params.append('business_activity', filters.business_activity)
                }
                if(filters.final_date){
                  params = params.append('final_date', filters.final_date)
                }

                  if(filters.page){
                    params = params.append('page', filters.page)
                  }
                  if(filters.page_size){
                    params = params.append('page_size', filters.page_size)
                  }




                return this.http.get<DataCustomer>(`${this.baseUrl}/customers/`, {params})
            }
            getDocumentesByCustomer(idCustomer:string){
              let params = new HttpParams();
              params = params.append('job_center', this.UserService.JobCenter);
              params = params.append('customer', idCustomer);


              return this.http.get<any>(`${this.baseUrl}/customer-documents/`, {params})
          }


            deleteItemCustomer(idItem:string){
              return this.http.delete<any>(`${this.baseUrl}/customers/${idItem}/`)
          }
          deleteUserPortalCustomer(idItem:string){
            return this.http.delete<any>(`${this.baseUrl}/customers-portal-accounts/${idItem}/`)
        }
          getCalendarByCustomer(idItem:string){
            return this.http.get<any>(`${this.baseUrl}/customers/${idItem}/pdf_calendar`)
          }

          saveCustomer(data:any){
            return this.http.post<any>(`${this.baseUrl}/customers/`,data);
          }

          patchLogoCustomer(data:any,idCustomer:string){
            return this.http.patch<any>(`${this.baseUrl}/customers/${idCustomer}/`,data);
          }
          saveTracing(data:any){
            return this.http.post<any>(`${this.baseUrl}/quotes-tracing/`,data);
          }

          changePasswordUserPortal(data:any){
            return this.http.post<any>(`${this.baseUrl}/customers-portal-accounts/${data.id}/change_password/`,data);
          }
          sendCredentials(data:any){
            return this.http.post<any>(`${this.baseUrl}/customers-portal-accounts/${data.id}/send_credentials/`,data);
          }

          saveUserPortalCustomer(data:any){
            return this.http.post<any>(`${this.baseUrl}/customers-portal-accounts/`,data);
          }


          changePasswordPortalCustomer(data:any){
            return this.http.post<any>(`${this.baseUrl}/customers-portal-accounts/`,data);
          }


          saveDocumentCustomer(data:any,idcustomer:string){
            return this.http.post<any>(`${this.baseUrl}/customer-documents/`,data);
          }

          patchDocumentCustomer(data:any,idDocument:string){
            return this.http.patch<any>(`${this.baseUrl}/customer-documents/${idDocument}/`,data);
          }

         putCustomer(data:any,idCustomer:string){
            return this.http.put<any>(`${this.baseUrl}/customers/${idCustomer}/`,data);
          }

          putTracing(data:any){
            return this.http.put<any>(`${this.baseUrl}/quotes-tracing/${data.id}/`,data);
          }
          putUserPortalCustomer(data:any){
            return this.http.put<any>(`${this.baseUrl}/customers-portal-accounts/${data.id}/`,data);
          }

          deleteDocumentCustomer(idItem:string){
            return this.http.delete<any>(`${this.baseUrl}/customer-documents/${idItem}/`)
        }


        getZonesControlByCustomer(filters:any,idCustomer:string){

          let params = new HttpParams();

          params = params.append('job_center', this.UserService.JobCenter);
          params = params.append('customer', idCustomer);

          if(filters.search){

              params =  params.append('search',  filters.search)
          }

            if(filters.indication){
              params = params.append('indication', filters.indication)
            }
            if(filters.page){
              params = params.append('page', filters.page)
            }
            if(filters.page_size){
              params = params.append('page_size', filters.page_size)
            }



          return this.http.get<any>(`${this.baseUrl}/pest-control/zones/`, {params})
      }


      getZonesByCheckPoint(filters:any,idCustomer:string){

        let params = new HttpParams();

        params = params.append('job_center', this.UserService.JobCenter);
        params = params.append('customer', idCustomer);

        if(filters.search){

            params =  params.append('search',  filters.search)
        }

          if(filters.indication){
            params = params.append('indication', filters.indication)
          }
          if(filters.page){
            params = params.append('page', filters.page)
          }
          if(filters.page_size){
            params = params.append('page_size', filters.page_size)
          }



        return this.http.get<any>(`${this.baseUrl}/check-points/zones/`, {params})
    }

    getNestingAreasTree(idCustomer:string){

      return this.http.get<any>(`${this.baseUrl}/customers/${idCustomer}/nesting_areas_tree/`)
    }
}
