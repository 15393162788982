import { UserService } from './user.service';



import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";

import { environment } from 'src/environments/environment';
import { LocalStorageService } from './local-storage-service';

@Injectable({
  providedIn: 'root'
})
export class AreasControlService {

  private baseUrl: string = environment.baseUrl
  OriginScreen: string = "" //CUSTOMERS || BUSINESS-ACTIVITES || CHECKPOINT

  constructor(private http: HttpClient,

    private UserService: UserService,
    private localStorageService: LocalStorageService) { }

  addStationBusinessPestControl(dataSave: any) {
    return this.http.post<any>(`${this.baseUrl}/business-pest/area-stations/massive/`, dataSave)
  }
  addStationCustomerPestControl(dataSave: any) {
    return this.http.post<any>(`${this.baseUrl}/pest-control/area-stations/massive/`, dataSave)
  }
  addCheckPoint(dataSave: any) {
    return this.http.post<any>(`${this.baseUrl}/check-points/checkpoints/massive/`, dataSave)
  }
  addPerimiterCustomerPestControl(dataSave: any) {
    return this.http.post<any>(`${this.baseUrl}/check-points/perimeters/`, dataSave)
  }
  addDataBusinessPestcontrol(nameCatalog: string, dataSave: any): Observable<any> {

    return this.http.post<any>(`${this.baseUrl}/business-pest/${nameCatalog}/`, dataSave)
  }
  addPerimetersPestcontrol(dataSave: any): Observable<any> {

    return this.http.post<any>(`${this.baseUrl}/check-points/perimeters/`, dataSave)
  }
  saveZoneCheckPoint(dataSave: any): Observable<any> {

    return this.http.post<any>(`${this.baseUrl}/check-points/zones/`, dataSave)
  }
  addDataCustomersPestcontrol(nameCatalog: string, dataSave: any): Observable<any> {

    return this.http.post<any>(`${this.baseUrl}/pest-control/${nameCatalog}/`, dataSave)
  }

  addDataCustomers(nameCatalog: string, dataSave: any): Observable<any> {

    return this.http.post<any>(`${this.baseUrl}/pest-control/${nameCatalog}/`, dataSave)
  }
  putDataCustomersPestcontrol(nameCatalog: string, idItem: string, dataSave: any): Observable<any> {

    return this.http.put<any>(`${this.baseUrl}/pest-control/${nameCatalog}/${idItem}/`, dataSave)
  }


  putDataCheckPointPestcontrol(idItem: string, dataSave: any): Observable<any> {

    return this.http.put<any>(`${this.baseUrl}/check-points/zones/${idItem}/`, dataSave)
  }

  putDataBusinessPestcontrol(nameCatalog: string, idItem: string, dataSave: any): Observable<any> {

    return this.http.put<any>(`${this.baseUrl}/business-pest/${nameCatalog}/${idItem}/`, dataSave)
  }
  putPerimeters(idItem: string, dataSave: any): Observable<any> {

    return this.http.put<any>(`${this.baseUrl}/check-points/perimeters/${idItem}/`, dataSave)
  }

  putDataCustomerPestcontrol(nameCatalog: string, idItem: string, dataSave: any): Observable<any> {

    return this.http.put<any>(`${this.baseUrl}/pest-control/${nameCatalog}/${idItem}/`, dataSave)
  }
  putDataCheckPointsPestcontrol(nameCatalog: string, idItem: string, dataSave: any): Observable<any> {

    return this.http.put<any>(`${this.baseUrl}/check-points/checkpoints/${idItem}/`, dataSave)
  }


  getCategoryAreas(filters: any) {

    let params = new HttpParams();

    params = params.append('job_center', this.UserService.JobCenter);
    //  params = params.append('business_activity', idRegister);

    if (filters.search) {

      params = params.append('search', filters.search)
    }


    return this.http.get<any>(`${this.baseUrl}/catalogs/area-categories/`, { params })
  }

  getTypeStations(filters: any) {

    let params = new HttpParams();

    params = params.append('job_center', this.UserService.JobCenter);
    //  params = params.append('business_activity', idRegister);

    if (filters.search) {

      params = params.append('search', filters.search)
    }
    if (filters.station_type) {

      params = params.append('station_type', filters.station_type)
    }


    return this.http.get<any>(`${this.baseUrl}/catalogs/station-types/`, { params })
  }

  getCheckPointControlByCustomer(filters: any, idRegister: string) {
    let params = new HttpParams();

    params = params.append('job_center', this.UserService.JobCenter);
    params = params.append('customer', idRegister);


    if (filters.search) {

      params = params.append('search', filters.search)
    }

    if (filters.indication) {
      params = params.append('indication', filters.indication)
    }
    if (filters.page) {
      params = params.append('page', filters.page)
    }
    if (filters.page_size) {
      params = params.append('page_size', filters.page_size)
    }



    return this.http.get<any>(`${this.baseUrl}/check-points/checkpoints/`, { params })
  }


  getDataBusinessById(nameEndpoint: string, idItem: any): Observable<any> {
    return this.http.get<any>(
      `${this.baseUrl}/business-pest/${nameEndpoint}/${idItem}/`
    );
  }

  getDataCustomerById(nameEndpoint: string, idItem: any): Observable<any> {
    return this.http.get<any>(
      `${this.baseUrl}/pest-control/${nameEndpoint}/${idItem}/`
    );
  }

  getDataCheckPoinById(idItem: any): Observable<any> {
    return this.http.get<any>(
      `${this.baseUrl}/check-points/checkpoints/${idItem}/`
    );
  }

  getNestingAreasTree(idBussinessLine: string) {

    return this.http.get<any>(`${this.baseUrl}/business-pest/area-stations/${idBussinessLine}/nesting_areas_tree/`)
  }
  getPerimeters(filters: any, idRegister: string) {

    let params = new HttpParams();

    params = params.append('job_center', this.UserService.JobCenter);
    params = params.append('customer', idRegister);

    if (filters.search) {

      params = params.append('search', filters.search)
    }

    if (filters.indication) {
      params = params.append('indication', filters.indication)
    }
    if (filters.page) {
      params = params.append('page', filters.page)
    }
    if (filters.page_size) {
      params = params.append('page_size', filters.page_size)
    }



    return this.http.get<any>(`${this.baseUrl}/check-points/perimeters/`, { params })
  }

  getZonesControlByBusiness(filters: any, idRegister: string) {

    let params = new HttpParams();

    params = params.append('job_center', this.UserService.JobCenter);
    params = params.append('business_activity', idRegister);

    if (filters.search) {

      params = params.append('search', filters.search)
    }

    if (filters.indication) {
      params = params.append('indication', filters.indication)
    }
    if (filters.page) {
      params = params.append('page', filters.page)
    }
    if (filters.page_size) {
      params = params.append('page_size', filters.page_size)
    }



    return this.http.get<any>(`${this.baseUrl}/business-pest/zones/`, { params })
  }


  getZonesControlByCustomer(filters: any, idRegister: string) {

    let params = new HttpParams();

    params = params.append('job_center', this.UserService.JobCenter);
    params = params.append('customer', idRegister);

    if (filters.search) {

      params = params.append('search', filters.search)
    }

    if (filters.indication) {
      params = params.append('indication', filters.indication)
    }
    if (filters.page) {
      params = params.append('page', filters.page)
    }
    if (filters.page_size) {
      params = params.append('page_size', filters.page_size)
    }



    return this.http.get<any>(`${this.baseUrl}/pest-control/zones/`, { params })
  }


  getAreasControlByBusiness(filters: any, idRegister: string) {

    let params = new HttpParams();

    params = params.append('job_center', this.UserService.JobCenter);
    params = params.append('business_activity', idRegister);

    if (filters.search) {

      params = params.append('search', filters.search)
    }



    return this.http.get<any>(`${this.baseUrl}/business-pest/nesting-areas/`, { params })
  }


  getaAreasControlByBusiness(filters: any, idRegister: string) {

    let params = new HttpParams();

    params = params.append('job_center', this.UserService.JobCenter);
    params = params.append('business_activity', idRegister);

    if (filters.search) {

      params = params.append('search', filters.search)
    }

    if (filters.indication) {
      params = params.append('indication', filters.indication)
    }
    if (filters.page) {
      params = params.append('page', filters.page)
    }
    if (filters.page_size) {
      params = params.append('page_size', filters.page_size)
    }
    if (filters.initial_date) {
      params = params.append('initial_date', filters.initial_date)
    }
    if (filters.final_date) {
      params = params.append('final_date', filters.final_date)
    }

    if (filters.zone) {
      params = params.append('zone', filters.zone)
    }
    if (filters.area_category) {
      params = params.append('area_category', filters.area_category)
    }




    return this.http.get<any>(`${this.baseUrl}/business-pest/nesting-areas/`, { params })
  }


  getPerimetersByCustomerPest(filters: any, idRegister: string) {

    let params = new HttpParams();

    params = params.append('job_center', this.UserService.JobCenter);
    params = params.append('customer', idRegister);

    if (filters.search) {

      params = params.append('search', filters.search)
    }

    if (filters.indication) {
      params = params.append('indication', filters.indication)
    }
    if (filters.page) {
      params = params.append('page', filters.page)
    }
    if (filters.page_size) {
      params = params.append('page_size', filters.page_size)
    }
    if (filters.initial_date) {
      params = params.append('initial_date', filters.initial_date)
    }
    if (filters.final_date) {
      params = params.append('final_date', filters.final_date)
    }





    return this.http.get<any>(`${this.baseUrl}/check-points/perimeters/`, { params })
  }
  getAreasByCustomerPest(filters: any, idRegister: string) {

    let params = new HttpParams();

    params = params.append('job_center', this.UserService.JobCenter);
    params = params.append('customer', idRegister);

    if (filters.search) {

      params = params.append('search', filters.search)
    }

    if (filters.indication) {
      params = params.append('indication', filters.indication)
    }
    if (filters.page) {
      params = params.append('page', filters.page)
    }
    if (filters.page_size) {
      params = params.append('page_size', filters.page_size)
    }
    if (filters.initial_date) {
      params = params.append('initial_date', filters.initial_date)
    }
    if (filters.final_date) {
      params = params.append('final_date', filters.final_date)
    }

    if (filters.zone) {
      params = params.append('zone', filters.zone)
    }
    if (filters.area_category) {
      params = params.append('area_category', filters.area_category)
    }




    return this.http.get<any>(`${this.baseUrl}/pest-control/nesting-areas/`, { params })
  }

  getaStationsControlByCustomer(filters: any, idRegister: string) {

    let params = new HttpParams();

    params = params.append('job_center', this.UserService.JobCenter);
    params = params.append('nesting_area__customer__id', idRegister);

    if (filters.search) {

      params = params.append('search', filters.search)
    }
    if (filters.nesting_area) {

      params = params.append('search', filters.search)
    }

    if (filters.page) {
      params = params.append('page', filters.page)
    }
    if (filters.page_size) {
      params = params.append('page_size', filters.page_size)
    }
    if (filters.station_type) {

      params = params.append('station_type', filters.station_type)
    }


    return this.http.get<any>(`${this.baseUrl}/pest-control/area-stations/`, { params })
  }

  getaStationsControlByBusiness(filters: any, idRegister: string) {

    let params = new HttpParams();

    params = params.append('job_center', this.UserService.JobCenter);
    params = params.append('business_activity', idRegister);

    if (filters.search) {

      params = params.append('search', filters.search)
    }
    if (filters.nesting_area) {

      params = params.append('search', filters.search)
    }

    if (filters.page) {
      params = params.append('page', filters.page)
    }
    if (filters.page_size) {
      params = params.append('page_size', filters.page_size)
    }
    if (filters.station_type) {

      params = params.append('station_type', filters.station_type)
    }




    return this.http.get<any>(`${this.baseUrl}/business-pest/area-stations/`, { params })
  }




  deleteItemBusinessPestcontrol(route: string, idItem: string) {
    return this.http.delete<any>(`${this.baseUrl}/business-pest/${route}/${idItem}/`)
  }


  deletePerimeterPestcontrol(route: string, idItem: string) {
    return this.http.delete<any>(`${this.baseUrl}/check-points/perimeters/${idItem}/`)
  }

  deleteItemCustomersPestcontrol(route: string, idItem: string) {
    return this.http.delete<any>(`${this.baseUrl}/pest-control/${route}/${idItem}/`)
  }
  deleteItemCheckPoint(route: string, idItem: string) {
    return this.http.delete<any>(`${this.baseUrl}/check-points/checkpoints/${idItem}/`)
  }

  deleteZoneCheckPoint(idItem: string) {
    return this.http.delete<any>(`${this.baseUrl}/check-points/zones/${idItem}/`)
  }

}
