import { NgModule } from '@angular/core';

import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { BadgeModule } from 'primeng/badge';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RippleModule } from 'primeng/ripple';
import { SidebarModule } from 'primeng/sidebar';
import { AppMenuComponent } from './app.menu.component';
import { AppMenuitemComponent } from './app.menuitem.component';

import { AppSidebarComponent } from './app.sidebar.component';

import { AppLayoutComponent } from './app.layout.component';
import { AppConfigModule } from './config/config.module';

import { CommonModule } from '@angular/common';
import { DropdownModule } from 'primeng/dropdown';
import { MenuModule } from 'primeng/menu';
import { StyleClassModule } from 'primeng/styleclass';
import { TooltipModule } from 'primeng/tooltip';
import { PrimengModule } from '../system/primeng/primeng.module';
import { SharedModule } from '../system/shared/shared.module';

@NgModule({
  declarations: [
    AppMenuitemComponent,

    AppSidebarComponent,

    AppMenuComponent,
    AppLayoutComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    InputTextModule,
    InputSwitchModule,
    MenuModule,
    RouterModule,
    DropdownModule,
    SidebarModule,
    StyleClassModule,
    BadgeModule,
    RadioButtonModule,
    InputSwitchModule,
    RippleModule,
    RouterModule,
    TooltipModule,
    AppConfigModule,
    SharedModule,
    PrimengModule
  ],
  exports: [AppLayoutComponent]
})
export class AppLayoutModule { }
