import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Subject } from "rxjs";

import { environment } from 'src/environments/environment';
import { DataCustomer } from '../core/models/customer.interface';
import { LocalStorageService } from './local-storage-service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class CalendarService {
  filterTable$ = new Subject<any>();

  private baseUrl: string = environment.baseUrl

  constructor(
    private http: HttpClient,
    private UserService: UserService,
    private localStorageService: LocalStorageService
  ) { }

  FilterTable(filters: any): void {
    this.filterTable$.next(filters);
  }

  createEvent(data: any) {
    return this.http.post<any>(`${this.baseUrl}/events/`, data);
  }

  updateEvent(data: any) {
    return this.http.put<any>(`${this.baseUrl}/events/${data.id}/`, data);
  }

  getEvents(filters: any) {
    let params = new HttpParams();
    params = params.append('job_center', this.UserService.JobCenter);

    if (filters.page) {
      params = params.append('page', filters.page)
    }

    if (filters.page_size) {
      params = params.append('page_size', filters.page_size)
    }

    if (filters.search) {
      params = params.append('search', filters.search)
    }

    if (filters.event_initial_date) {
      params = params.append('event_initial_date', filters.event_initial_date)
    }

    if (filters.event_final_date) {
      params = params.append('event_final_date', filters.event_final_date)
    }

    if (filters.real_initial_date) {
      params = params.append('real_initial_date', filters.real_initial_date)
    }

    if (filters.real_final_date) {
      params = params.append('real_final_date', filters.real_final_date)
    }

    if (filters.employee) {
      params = params.append('employee', filters.employee)
    }

    if (filters.group) {
      params = params.append('group', filters.group)
    }

    if (filters.customer) {
      params = params.append('customer', filters.customer)
    }

    if (filters.service_type) {
      params = params.append('service_type', filters.service_type)
    }

    if (filters.event_type) {
      params = params.append('event_type', filters.event_type)
    }

    if (filters.quote) {
      params = params.append('quote', filters.quote)
    }

    if (filters.ticket) {
      params = params.append('ticket', filters.ticket)
    }

    if (filters.cancellation_reason) {
      params = params.append('cancellation_reason', filters.cancellation_reason)
    }

    if (filters.status) {
      params = params.append('status__name', filters.status)
    }

    if (filters.ordering) {
      params = params.append('ordering', filters.ordering)
    }

    return this.http.get<DataCustomer>(`${this.baseUrl}/events/`, { params })
  }

  getDocumentsById(id: string) {
    return this.http.get<any>(`${this.baseUrl}/events/${id}/documents_pdf/`)
  }

  getStatusOptions() {
    return this.http.get<any>(`${this.baseUrl}/catalogs/statuses/?search=event`)
  }

  getDocument(urlDocument: string) {
    return this.http.get<any>(`${this.baseUrl}/${urlDocument}`)
  }

  getViewQuoteById(id: string) {
    return this.http.get<any>(`${this.baseUrl}/quotes/${id}/pdf/`)
  }

  aproveQuote(id: string) {
    return this.http.patch<any>(`${this.baseUrl}/quotes/${id}/approved`, {});
  }

  cancelQuote(id: string) {
    return this.http.patch<any>(`${this.baseUrl}/quotes/${id}/cancelled`, {});
  }

  cancelEvent(data: any) {
    return this.http.patch<any>(`${this.baseUrl}/events/${data.id}/`, data);
  }

  reactivateEvent(data: any) {
    return this.http.patch<any>(`${this.baseUrl}/events/${data.id}/`, data);
  }

  deleteQuote(id: string) {
    return this.http.delete<any>(`${this.baseUrl}/quotes/${id}/`)
  }

  deleteFolloUpQuote(idFollow: string) {
    return this.http.delete<any>(`${this.baseUrl}/quotes-tracing/${idFollow}/`)
  }

  getQuotesTracingByQuoteId(idQuote: string) {
    let params = new HttpParams();

    params = params.append('job_center', this.UserService.JobCenter);
    params = params.append('quote', idQuote);

    return this.http.get<any>(`${this.baseUrl}/quotes-tracing/`, { params })
  }

  getUsersPortalByCustomer(filters: any, idCustomer: string) {
    let params = new HttpParams();

    params = params.append('job_center', this.UserService.JobCenter);
    params = params.append('customer', idCustomer);

    if (filters.is_main) {
      params = params.append('is_main', filters.is_main)
    }

    if (filters.search) {
      params = params.append('search', filters.search)
    }

    if (filters.page) {
      params = params.append('page', filters.page)
    }

    if (filters.page_size) {
      params = params.append('page_size', filters.page_size)
    }

    return this.http.get<DataCustomer>(`${this.baseUrl}/customers-portal-accounts/`, { params })
  }

  getDocumentesByCustomer(idCustomer: string) {
    let params = new HttpParams();

    params = params.append('job_center', this.UserService.JobCenter);
    params = params.append('customer', idCustomer);

    return this.http.get<any>(`${this.baseUrl}/customer-documents/`, { params })
  }

  deleteUserPortalCustomer(idItem: string) {
    return this.http.delete<any>(`${this.baseUrl}/customers-portal-accounts/${idItem}/`)
  }

  getCalendarByCustomer(idItem: string) {
    return this.http.get<any>(`${this.baseUrl}/customers/${idItem}/pdf_calendar`)
  }

  getEventById(id: string) {
    return this.http.get<any>(`${this.baseUrl}/events/${id}`)
  }

  saveCustomer(data: any) {
    return this.http.post<any>(`${this.baseUrl}/customers/`, data);
  }

  changePasswordUserPortal(data: any) {
    return this.http.post<any>(`${this.baseUrl}/customers-portal-accounts/${data.id}`, data);
  }

  sendCredentials(data: any) {
    return this.http.post<any>(`${this.baseUrl}/customers-portal-accounts/${data.id}/send_credentials`, data);
  }

  saveUserPortalCustomer(data: any) {
    return this.http.post<any>(`${this.baseUrl}/customers-portal-accounts/`, data);
  }

  changePasswordPortalCustomer(data: any) {
    return this.http.post<any>(`${this.baseUrl}/customers-portal-accounts/`, data);
  }

  saveDocumentCustomer(data: any) {
    return this.http.post<any>(`${this.baseUrl}/customer-documents/${data.id}`, data);
  }

  patchDocumentCustomer(data: any, idDocument: string) {
    return this.http.patch<any>(`${this.baseUrl}/customer-documents/${idDocument}/`, data);
  }

  putCustomer(data: any) {
    return this.http.put<any>(`${this.baseUrl}/customers/${data.id}`, data);
  }

  putUserPortalCustomer(data: any) {
    return this.http.put<any>(`${this.baseUrl}/customers-portal-accounts/${data.id}`, data);
  }

  deleteDocumentCustomer(idItem: string) {
    return this.http.delete<any>(`${this.baseUrl}/customer-documents/${idItem}/`)
  }

  deleteEvent(idEvent: string) {
    return this.http.delete<any>(`${this.baseUrl}/events/${idEvent}/`)
  }

  getZonesByCheckPoint(filters: any, idCustomer: string) {
    let params = new HttpParams();

    params = params.append('job_center', this.UserService.JobCenter);
    params = params.append('customer', idCustomer);

    if (filters.search) {
      params = params.append('search', filters.search)
    }

    if (filters.indication) {
      params = params.append('indication', filters.indication)
    }

    if (filters.page) {
      params = params.append('page', filters.page)
    }

    if (filters.page_size) {
      params = params.append('page_size', filters.page_size)
    }

    return this.http.get<any>(`${this.baseUrl}/check-points/zones/`, { params })
  }
}
