import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
//import { DateFnsModule } from 'ngx-date-fns';

import { AppComponent } from './app.component';
import { AppLayoutModule } from './layout/app.layout.module';
import { RoutingModule } from './routing.module';
import { interceptorProviders } from './system/core/interceptors/interceptors';
import { PrimengModule } from './system/primeng/primeng.module';
import { MessagesService } from './system/services/message.service';
import { SharedModule } from './system/shared/shared.module';

@NgModule({
  imports: [//
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppLayoutModule,
    PrimengModule,
    SharedModule,
    RoutingModule
  ],
  declarations: [
    AppComponent,
  ],
  providers: [
    interceptorProviders,
    DialogService, MessagesService, MessageService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
