import { Component, inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DialogService } from 'primeng/dynamicdialog';
import * as XLSX from 'xlsx';

import { TYPE_TOAST } from 'src/app/system/core/enums/ToastsEnums';
import { CatalogsEnum, URL_ENDPOINTS } from 'src/app/system/core/enums/catalogs.model';
import { CalendarService } from 'src/app/system/services/calendar.service';
import { DateService } from 'src/app/system/services/date.service';
import { LoadingService } from 'src/app/system/services/loading.service';
import { MessagesService } from 'src/app/system/services/message.service';
import { PermissionsServices } from 'src/app/system/services/permissions.service';
import { MipFormsComponent } from '../../components/MIP-FORMS/mip-forms.component';
import { CancelEventComponent } from '../../components/cancel-event/cancel-event.component';
import { CreateUpdateEventFormComponent } from '../../components/create-update-event-form/create-update-event-form.component';
import { DocumentsComponent } from '../../components/documents/documents.component';
import { FiltersCalendarComponent } from '../../components/filters-calendar/filters-calendar.component';
import { ReactivateEventComponent } from '../../components/reactivate-event/reactivate-event.component';

@Component({
  selector: 'app-events-calendar',
  templateUrl: './events-calendar.component.html',
  styleUrls: ['./events-calendar.component.scss'],
})
export class EventsCalendarComponent implements OnInit {
  public index: number = 0;
  public Catalog = CatalogsEnum;
  public groupSearch = '';
  public eventCustomer: string = ""
  public dataForm!: FormGroup;
  public events: any[] = [];
  public statusOptions: any[] = [];
  public filteredStatus: any[] | undefined;

  public FormGroup: FormGroup = this.FormBuilder.group({
    group: [null],
    event_type: [null],
    employee: [null],
    customer: [null],
    quote: [null],
    service_type: [null],
    plague: [null],
  });

  public permissionsService = inject(PermissionsServices);

  constructor(
    private MessagesService: MessagesService,
    private DialogService: DialogService,
    private FormBuilder: FormBuilder,
    private CalendarService: CalendarService,
    private loading: LoadingService,
    private DateService: DateService,
  ) { }

  ngOnInit(): void {
    if (localStorage.getItem("eventCustomer")) {
      this.eventCustomer = localStorage.getItem("eventCustomer") || ""
      this.addEvent()
    }

    this.loadDataForm();
    this.loadStatusOptions();
    this.loadData();
  }

  handleChange(event: any) {
    this.index = event.index;
  }

  openFilters() {
    const dialog = this.DialogService.open(FiltersCalendarComponent, {
      header: 'Filtrar calendario',
      modal: true,
      width: '30rem',
      //height:"100%",
      closable: true,
      draggable: false,
      // maximizable: true,
      resizable: true,
      closeOnEscape: true,
      dismissableMask: true,
    });

    dialog.onClose.subscribe((res) => { });
  }

  URL_CATALOGS = URL_ENDPOINTS;

  resetFilters() {
    this.FormGroup.reset();
    this.CalendarService.FilterTable(null);
  }

  filterByGroup(e: any) {
    this.CalendarService.FilterTable(this.FormGroup.value);
  }

  filterStatus(event: any) {
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < (this.statusOptions as any[]).length; i++) {
      let country = (this.statusOptions as any[])[i];
      if (country.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(country);
      }
    }

    this.filteredStatus = filtered;
  }

  addEvent() {
    if (this.permissionsService.hasPermission("events", "add")) {
      const dialog = this.DialogService.open(CreateUpdateEventFormComponent, {
        header: 'Nuevo evento',
        modal: true,
        width: '50%',
        //height:"100%",
        closable: true,
        draggable: false,
        // maximizable: true,
        resizable: true,
        closeOnEscape: true,
        dismissableMask: true,
        data: {
          Event: null,
        },
      });

      dialog.onClose.subscribe((res) => {
        if (res?.success) {
          this.CalendarService.FilterTable({});
        }
      });
    }
  }

  onFilterInput(event: any, filter: string) {
 
 
    if (filter == "search") this.dataForm.value.search = event.target.value;
    if (filter == "employee") this.dataForm.value.employee = event.id;
    if (filter === "plague") this.dataForm.value.plague = event.id;
    if (filter == "estatus") this.dataForm.value.status = event.target.value;
    if (filter == "customer") this.dataForm.value.customer = event?.id;
    if (filter == "status") this.dataForm.value.status = event;

    this.loadData();
    // this.dt1.filter(inputValue, filter, 'contains');
  }

  loadData(): void {
  
    this.CalendarService.getEvents(this.dataForm.value).subscribe((response) => {
      this.loading.Hide();

      this.events = response.items;
      console.log(this.events);
      
      this.dataForm.value.page_size = response.page_size;
      this.dataForm.value.page = response.page;
      this.dataForm.value.totalRecords = response.total;
    });
  }

  loadDataForm(): void {
    this.dataForm = this.FormBuilder.group({
      page_size: 10,
      first: 0,
      page: 1,
      rows: 10,
      search: '',
      status: '',
      employee: '',
      customer:'',
      totalRecords: 0,
      range_date: '',
      event_initial_date: '',
      event_final_date: ''
    });
  }

  loadStatusOptions() {
    this.CalendarService.getStatusOptions().subscribe((res: any[]) => {
      this.statusOptions = res.map((status) => status.name);
    });
  }

  onPageChange(event: any) {
    this.dataForm.value.first = event.first;
    this.dataForm.value.page_size = event.rows;
    this.dataForm.value.pageCount = event.pageCount;
    this.dataForm.value.page = event.page + 1;
    this.loadData();
  }

  resetTable() {
    this.dataForm.patchValue({
      page_size: 10,
      first: 0,
      page: 1,
      rows: 10,
      search: '',
      status: '',
      employee: '',
      customer:'',
      range_date: '',
      event_initial_date: '',
      event_final_date: '',
      totalRecords: 0,
    })

    this.loadData();
  }

  searchStatus(event: any) {
    let filtered: any[] = [];
    let query = event.query;

    console.log('Status Options: ', this.statusOptions);

    // for (let i = 0; i < (this.statusOptions as any[]).length; i++) {
    //   let country = (this.statusOptions as any[])[i];
    //   if (country.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
    //     filtered.push(country);
    //   }
    // }

    // this.statusOptions = filtered;
  }

  editEvent(event: any) {
    this.loading.Show();

    this.CalendarService.getEventById(event.id).subscribe((res) => {
      this.loading.Hide();
      let Date = null;

      const dialog = this.DialogService.open(CreateUpdateEventFormComponent, {
        header: 'Evento ' + event.folio,
        modal: true,
        width: '50%',
        //height:"100%",
        closable: true,
        draggable: false,
        // maximizable: true,
        resizable: true,
        closeOnEscape: true,
        dismissableMask: true,
        data: {
          Event: res.data,
          Date,
        },
      });

      dialog.onClose.subscribe((res) => {
        // this.ref.close({success:true})
        this.loadData();
      });
    });
  }

  cancelEvent(event: any) {
    this.CalendarService.getEventById(event.id).subscribe((res) => {
      this.loading.Hide();
      let Date = null;

      const dialog = this.DialogService.open(CancelEventComponent, {
        header: 'Cancelar evento ' + event.folio,
        modal: true,
        width: '30rem',
        closable: true,
        draggable: false,
        resizable: true,
        closeOnEscape: true,
        dismissableMask: true,
        data: {
          Event: res.data,
        },
      });

      dialog.onClose.subscribe((res) => {
        this.loadData();
      });
    });
  }

  reactivateEvent(event: any) {
    this.loading.Show()

    this.CalendarService.getEventById(event.id).subscribe((res) => {
      this.loading.Hide();

      const dialog = this.DialogService.open(ReactivateEventComponent, {
        header: 'Reactivar evento ' + event.folio,
        modal: true,
        width: '30rem',
        closable: true,
        draggable: false,
        resizable: true,
        closeOnEscape: true,
        dismissableMask: true,
        data: {
          Event: res.data,
        },
      });

      dialog.onClose.subscribe((res) => {
        this.loadData();
      });
    });
  }

  deleteEvent(event: any) {
    this.MessagesService.confirm("¿Esta seguro de eliminar el event? " + event.folio, () => {
      this.loading.Show();
      this.CalendarService.deleteEvent(event.id).subscribe((res) => {
        this.loading.Hide();
        this.loadData();
        this.MessagesService.ShowMessage(TYPE_TOAST.SUCCESS, "Eliminado correctamente", "");
      })
    });
  }

  CrudMipForms(event: any) {
    this.CalendarService.getEventById(event.id).subscribe((res) => {
      this.loading.Hide();

      const dialog = this.DialogService.open(MipFormsComponent, {
        header: 'Folio ' + event.folio,
        modal: true,
        width: window.innerWidth + 'px',  // Ancho inicial igual al ancho de la ventana
        height: window.innerHeight + 'px',
        closable: true,
        draggable: false,
        maximizable: true,
        resizable: true,
        closeOnEscape: true,
        dismissableMask: true,
        data: {
          Event: res.data,
        },
      });

      dialog.onClose.subscribe((res) => {
        this.loadData();
      });
    });
  }

  openDocuments(event: any) {
    this.CalendarService.getEventById(event.id).subscribe((res) => {
      this.loading.Hide();

      const dialog = this.DialogService.open(DocumentsComponent, {
        header: 'Folio ' + event.folio,
        modal: true,
        width: '32rem',  // Ancho inicial igual al ancho de la ventana

        closable: true,
        draggable: false,
        maximizable: true,
        resizable: true,
        closeOnEscape: true,
        dismissableMask: true,
        data: {
          Event: res.data,
        },
      });

      dialog.onClose.subscribe((res) => {
        //  this.ref.close({success:true})
        this.loadData();
      });
    });
  }

  exportExcel() {
    const columnNames = Array.from(new Set(this.events.flatMap(Object.keys)));

    let dataExcel = this.events.map((res) => ({
      Folio: res.folio,
      Nombre: res.title,
      FechaInicial: res.initial_date,
      FechaFinal: res.final_date,
      Estatus: res.status.name,
      Empleado: res?.employee[0]?.name ?? "",
       Cliente: res?.customer?.name ?? ""
    }));

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(dataExcel);
    const workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ['data'],
    };

    const buffer: any = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });

    let fileName = "Eventos";

    const data: Blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
    });

    const link: HTMLAnchorElement = document.createElement('a');
    link.href = window.URL.createObjectURL(data);

    link.download = `${fileName}.xlsx`;
    link.click();
  }

  searchDate() {
    let initial_date!: string, final_date: string;
    let range_date: string = this.dataForm.value.range_date[1];

    if (range_date !== null) {
      initial_date = this.DateService.getFormatDataDate(this.dataForm.value.range_date[0]);
      final_date = this.DateService.getFormatDataDate(this.dataForm.value.range_date[1]);

      console.log(initial_date);
      console.log(final_date);

      this.dataForm.patchValue({
        event_initial_date: initial_date,
        event_final_date: final_date
      });

      this.loadData()
    }
  }

  getEventStatusTag(status: string): string {
    const SEVERITY_DICT: any = {
      "event-completed": "success",
      "event-created": "primary",
      "event-cancelled": "danger",
      "event-in-progress": "secondary",
    }

    if (status && status in SEVERITY_DICT) {
      return SEVERITY_DICT[status]
    }

    return "secondary"
  }

  getStatusForMenu(status: string): string {
    const EVENT_STATUS_DICT: any = {
      "event-completed": "completed",
      "event-created": "created",
      "event-cancelled": "cancelled",
      "event-in-progress": "created",
    }

    if (status && status in EVENT_STATUS_DICT) {
      return EVENT_STATUS_DICT[status]
    }

    return "created"
  }
}
