import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phoneNumber'
})
export class PhoneNumberPipe implements PipeTransform {
  transform(value: string): string {
    if (!value || value.length !== 10) {
      return value;
    }

    const formatted = `${value.slice(0, 3)}-${value.slice(3, 6)}-${value.slice(6)}`;
    return formatted;
  }
}
