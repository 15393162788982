<div class="">
<p class="mt-0 fw-bold h4"><span class="text-danger">{{Event?.folio}}</span> - <span class="fw-bold h3 text-dan" [ngClass]="{'text-success': Event.status.key_string === 'event-completed', 'text-primary' :  Event.status.key_string === 'event-created' }"> {{Event.status.name}}</span>
</p>


  <p-image *ngIf="Event.customer.logo" [src]="Event.customer.logo" alt="Image" width="100" [preview]="true">
    <br>
  </p-image>

  <div  class="grid formgrid   ">

    <div class="col-12  mt-4">
      <p class="mt-1"><span class="fw-bold h6">Evento: </span> {{Event?.title}}</p>
     
      <p class="mt-1"><span class="fw-bold h6">Cliente: </span> {{Event?.customer.name}}</p>
     
      <p class="mt-1"><span class="fw-bold h6">Dirección: </span> {{Event.customer.address}}</p>
      <p class="mt-1"><span class="fw-bold h6">Télefono: </span> {{Event.customer.phone}}</p>
      <p class="mt-1"><span class="fw-bold h6">Contacto: </span> {{Event.customer.contact_name}}</p>
      <p *ngIf="Event.customer.contact_phone" class="mb-2"><span class="fw-bold h6">Télefono contacto:</span>
        {{Event.customer.contact_phone}}</p>
      <p class="mt-1"><span class="fw-bold h6">Nombre Evento: </span> {{Event.event_type.name}}</p>

      <p *ngIf="Event.plague.length > 0" class="mt-1"><span class="fw-bold h6">Plagas: </span> <span
          *ngFor="let element of Event.plague; let l = last">{{element?.name}}<span *ngIf="!l">, </span></span></p>
      <p class="mt-1"><span class="fw-bold h6">Hora Inicio: </span> {{Event.initial_hour + ' - ' + Event.initial_date }}
      </p>
      <p class="mt-1"><span class="fw-bold h6">Hora Fin: </span> {{Event.final_hour + ' - ' + Event.final_date }}</p>
      <p class="mt-1"><span class="fw-bold h6">Técnico: </span> <span
          *ngFor="let employee of Event.employee; let l = last "> {{employee.name}} <span *ngIf="!l">,</span> </span>
      </p>
      <p class="mt-1"><span class="fw-bold h6">Total: </span> {{Event.total | currency: '': '$': '1.2-2'}}</p>


    </div>



  </div>
  <div class="d-flex justify-content-center mt-3">
    <div *appHasPermission="permissionsService.PERMISSIONS_READ.MIP">
      <p-button (click)="CrudMipForms()" type="button" icon="fa-solid fa-file-pen" pTooltip="Ver formularios"
        tooltipPosition="top" styleClass="p-button p-button-rounded p-button-outlined me-2">
      </p-button>
    </div>
    <div *appHasPermission="permissionsService.PERMISSIONS_ADD.EVENTS">
      <p-button (click)="editEvent()" type="button" icon="fa-solid fa-pen" pTooltip="Editar Información"
        tooltipPosition="top" styleClass="p-button p-button-rounded p-button-outlined me-2">
      </p-button>
    </div>
    <div *appHasPermission="permissionsService.PERMISSIONS_ADD.EVENTS">
      <p-button (click)="deleteEvent()" type="button" icon="fa-solid fa-trash" pTooltip="Eliminar evento"
        tooltipPosition="top" styleClass="p-button p-button-rounded p-button-outlined text-danger">
      </p-button>

    </div>
    <div *appHasPermission="permissionsService.PERMISSIONS_ADD.EVENTS">
      <p-button *ngIf="Event.status.name != 'Cancelado'" (click)="cancelEvent()" type="button" icon="fa-solid fa-ban"
        pTooltip="Cancelar evento" tooltipPosition="top"
        styleClass="p-button p-button-rounded p-button-outlined text-danger ms-2">
      </p-button>
    </div>

    <div *appHasPermission="permissionsService.PERMISSIONS_ADD.EVENTS">
      <p-button *ngIf="Event.status.name == 'Cancelado'" (click)="reactivateEvent()" type="button"
        icon="fa-solid fa-check" pTooltip="Reactivar evento" tooltipPosition="top"
        styleClass="p-button p-button-rounded p-button-outlined text-success ms-2">
      </p-button>
    </div>
    <div>
      <p-button *ngIf="Event.status.key_string == 'event-completed'" (click)="openDocuments()" type="button"
        icon="fa-solid fa-file-pdf" pTooltip="Documentación" tooltipPosition="top"
        styleClass="p-button p-button-rounded p-button-outlined text-danger ms-2">
      </p-button>
    </div>


  </div>
</div>