
<form [formGroup]="Form">
  <div class="grid formgrid   ">
    <div class="col-12 md:col-6 mt-4">
      <app-select (onSelect)="getNewForm($event)" [Catalog]="Catalog.EVENT_TYPES" field="name" [isDropdown]="true"
        type="p-autocomplete" [isMultiple]="false" label="Tipo evento" formControlName="event_type"></app-select>
    </div>


    <div class="col-12 md:col-6 mt-4">
      <span class="w-100 p-fluid p-float-label p p-input-icon-left">
        <i class="fa-solid fa-pencil"></i>
        <input formControlName="title" type="text" pInputText placeholder="Titulo" />
        <label for="title">Titulo</label>
      </span>
    </div>

    <div class="col-12 md:col-6 mt-4">
      <div *ngIf="actualClient != ''; else SelectClient ">
        <span class="w-100 p-fluid">
          <label for="title">Cliente</label>
          <input formControlName="customer" type="text" pInputText [placeholder]="actualClient" [value]="" dis>
        </span>
      </div>

      <ng-template #SelectClient>
        <app-select [isMain]="false" [UrlCatalog]="URL_CATALOGS.CUSTOMERS_DROPDOWN" field="name" [isDropdown]="false"
          type="select-generic" [isMultiple]="false" label="Cliente" formControlName="customer">
        </app-select>

        <div class=" mt-2 text-start mb-4">
          <button (click)="addCustomer()" icon="pi pi-plus" pButton pRipple type="button" label="Agregar nuevo cliente"
            class="p-button-raised btn-sm "></button>
        </div>
      </ng-template>
    </div>

    <div *ngIf="Form.value.hasOwnProperty('ticket')" class="col-12 md:col-6 mt-4">
      <app-select [customer]="Form.value.customer.id" [Catalog]="Catalog.TICKETS" field="folio" [isDropdown]="true"
        type="p-autocomplete-administrative" [isMultiple]="false" label="Tickets" formControlName="ticket"></app-select>
    </div>
    <div class="col-12 md:col-6 mt-4 d-flex justify-content-between">
      <div>
        <span class="w-100 p-fluid p-float-label p p-input-icon-left">
          <i class="fa-solid fa-calendar"></i>
          <p-calendar [showIcon]="true" appendTo="body" formControlName="initial_date" [showTime]="true"></p-calendar>
          <label for="ContactCustomer">Fecha inicial</label>
        </span>
      </div>
      <div>
        <span class="w-100 p-fluid p-float-label p p-input-icon-left">
          <i class="fa-solid fa-calendar"></i>
          <p-calendar [showIcon]="true" appendTo="body" formControlName="final_date" [showTime]="true"></p-calendar>
          <label for="final_date">Fecha final</label>
        </span>
      </div>
    </div>

    <div *ngIf="Form.value.hasOwnProperty('quote')" class="col-12 md:col-6 mt-4">
      <app-select (onSelect)="fillFormByQuote($event)" [Catalog]="Catalog.QUOTES" field="folio" [isDropdown]="false"
        type="p-autocomplete-administrative" [isMultiple]="false" label="Cotización"
        formControlName="quote"></app-select>
    </div>

    <div *ngIf="Form.value.hasOwnProperty('total')" class="col-12 md:col-6 mt-4">
      <span class="w-100 p-fluid p-float-label p p-input-icon-left">
        <i class="fa-solid fa-dollar-sign"></i>
        <input formControlName="total" type="number" pInputText placeholder="Costo" [disabled]="isDisabled" />
        <label for="title">Costo</label>

      </span>
    </div>



    <div *ngIf="Form.value.hasOwnProperty('group')" class="col-12 md:col-6 mt-4">
      <app-select [Catalog]="Catalog.GROUPS" field="name" [isDropdown]="true" type="p-autocomplete-administrative"
        [isMultiple]="true" label="Grupos" formControlName="group" (onSelect)="changeSelected($event)"></app-select>

    </div>


    <div *ngIf="Form.value.hasOwnProperty('service_type')" class="col-12 md:col-6 mt-4">
      <app-select [Catalog]="Catalog.SERVICE_TYPE" field="name" [isDropdown]="true" type="p-autocomplete"
        [isMultiple]="false" label="Tipo Servicio" formControlName="service_type" (onSelect)="serviceSelected($event)"
        [isDisabled]="isDisabled"></app-select>
    </div>


    <div *ngIf="Form.value.hasOwnProperty('employee');" class="col-12 md:col-6 mt-4">
      <app-select [Catalog]="Catalog.EMPLOYEES" [idGroup]="groupSelected" field="name" [isDropdown]="true"
        type="p-autocomplete-administrative" [isMultiple]="true" label="Empleado"
        formControlName="employee"></app-select>
    </div>



    <div *ngIf="Form.value.hasOwnProperty('plague')" class="col-12 md:col-6 mt-4">
      <app-select [Catalog]="Catalog.PLAGUES" field="name" [isDropdown]="true" type="p-autocomplete" [isMultiple]="true"
        label="Plagas" formControlName="plague" [isDisabled]="isDisabled"></app-select>
    </div>

    <div *ngIf="Form.value.hasOwnProperty('comments')" class="col-12 md:col-12 mt-4">
      <span class="p-fluid mt-5">
        <textarea formControlName="comments" placeholder="Comentarios" rows="3" cols="30" pInputTextarea></textarea>
      </span>
    </div>







  </div>

  <div class="d-flex justify-content-center mt-3">

    <div class="field-checkbox">
      <p-checkbox (onChange)="RepeatEvent()" [binary]="true" formControlName="repeat_event" name="repeat_event"
        id="repeat_event"></p-checkbox>
      <label for="repeat_event">Repetir evento</label>
    </div>

  </div>


</form>
<br>


<!--REPEAT EVENT-->

<form [formGroup]="FormRepeatEvent">
  <div *ngIf="this.Form.value.repeat_event" class="grid formgrid ">
    <div class="col-12 md:col-12">
      <p-divider layout="horizontal" align="center">
        <span class="p-tag">Repetir</span>
      </p-divider>
    </div>
    <div class="col-12 md:col-6 mt-4">
      <span class="w-100 p-fluid p-float-label">
        <p-autoComplete [multiple]="false" formControlName="repeat_type" [showEmptyMessage]="true"
          [suggestions]="filteredRepeatTypes" appendTo="body" (completeMethod)="filterRepeatType($event)"
          (onSelect)="SelectRepeatType($event)" [minLength]="1" [dropdown]="true" field="label"></p-autoComplete>
        <label for="autocomplete">Repetir</label>
      </span>
    </div>

    <div class="col-12 md:col-6 mt-4">
      <span *ngIf="repeat_type == 'MONTHLY'" class="w-100 p-fluid p-float-label">
        <input pInputText type="number" formControlName="every">
        <label>Cada mes</label>
      </span>
      <span *ngIf="repeat_type == 'WEEKLY'" class="w-100 p-fluid p-float-label">
        <input pInputText type="number" formControlName="every">
        <label>Cada semana</label>
      </span>
      <span *ngIf="repeat_type == 'DAILY'" class="w-100 p-fluid p-float-label">
        <input pInputText type="number" formControlName="every">
        <label>Cada Día</label>
      </span>
      <span *ngIf="repeat_type == 'HOURLY'" class="w-100 p-fluid p-float-label">
        <input pInputText type="number" formControlName="every">
        <label>Cada Hora</label>
      </span>
      <span *ngIf="repeat_type == 'PERSONAL'" class="w-100 p-fluid p-float-label">
        <p-calendar appendTo="body" [(ngModel)]="datesPersonal" [ngModelOptions]="{standalone: true}"
          selectionMode="multiple" [showTime]="true"></p-calendar>
        <label>Rango</label>
      </span>



    </div>

    <div *ngIf="repeat_type == 'YEARLY'" class="col-12 md:col-6 mt-4 ">
      <div class="grid formgrid d-flex align-items-center">
        <div class="col-12 md:col-2 ">
          <p-radioButton name="YEARLY" inputId="YearlyDays" value="onDay" [ngModelOptions]="{standalone: true}"
            [(ngModel)]="YearlyRadioButton"></p-radioButton>
          <label for="ingredient1" class="ml-2">Mes</label>
        </div>
        <div class="col-12 md:col-5 mt-2">
          <span class="w-100 p-fluid p-float-label">
            <p-dropdown [disabled]="YearlyRadioButton != 'onDay'" formControlName="month" appendTo="body"
              optionLabel="label" [showTransitionOptions]="'0ms'" [options]="Months" appendTo="body"></p-dropdown>
            <label for="autocomplete">Mes</label>
          </span>
        </div>
        <div class="col-12 md:col-5 mt-2">
          <span class="w-100 p-fluid p-float-label">
            <p-dropdown [disabled]="YearlyRadioButton != 'onDay'" formControlName="day" appendTo="body"
              optionLabel="day" [showTransitionOptions]="'0ms'" [options]="numberDaysMonth"
              appendTo="body"></p-dropdown>
            <label for="autocomplete">Día</label>
          </span>
        </div>
      </div>


    </div>

    <div *ngIf="repeat_type == 'YEARLY'" class="col-12 md:col-6 mt-4">

    </div>

    <div *ngIf="repeat_type == 'YEARLY'" class="col-12 md:col-6 mt-4 ">
      <div class="grid formgrid d-flex align-items-center">
        <div class="col-12 md:col-2 ">
          <p-radioButton name="YEARLY" inputId="YearlyDays" value="onThe" [ngModelOptions]="{standalone: true}"
            [(ngModel)]="YearlyRadioButton"></p-radioButton>
          <label for="ingredient1" class="ml-2">Por</label>
        </div>
        <div class="col-12 md:col-5 mt-2">
          <span class="w-100 p-fluid p-float-label">
            <p-dropdown [disabled]="YearlyRadioButton == 'onDay'" formControlName="DayWithLetter" appendTo="body"
              optionLabel="label" [showTransitionOptions]="'0ms'" [options]="DaysWitLetter"
              appendTo="body"></p-dropdown>
          </span>
        </div>
        <div class="col-12 md:col-5 mt-2">

          <span class="w-100 p-fluid p-float-label">
            <p-dropdown [disabled]="YearlyRadioButton == 'onDay'" formControlName="MonthWithLetter" appendTo="body"
              optionLabel="label" [showTransitionOptions]="'0ms'" [options]="MontlhyDaysWithLetter"
              appendTo="body"></p-dropdown>
            <label for="autocomplete">Día</label>
          </span>
        </div>

      </div>


    </div>
    <div *ngIf="repeat_type == 'YEARLY'" class="col-12 md:col-3 mt-4">
      <span class="w-100 p-fluid p-float-label mt-2">
        <p-dropdown [disabled]="YearlyRadioButton == 'onDay'" formControlName="month" appendTo="body"
          optionLabel="label" [showTransitionOptions]="'0ms'" [options]="Months" appendTo="body"></p-dropdown>
        <label for="autocomplete">Mes</label>
      </span>
    </div>



    <!--MONTLY-->
    <div *ngIf="repeat_type == 'MONTHLY'" class="col-12 md:col-6 mt-4 ">
      <div class="grid formgrid d-flex align-items-center">
        <div class="col-12 md:col-4 ">
          <p-radioButton name="MONTHLY" inputId="MontlhyDays" value="onDay" [ngModelOptions]="{standalone: true}"
            [(ngModel)]="MonthlyRadioButton"></p-radioButton>
          <label for="MontlhyDays" class="ml-2">Día de mes</label>
        </div>
        <div class="col-12 md:col-8 mt-2">
          <span class="w-100 p-fluid p-float-label">
            <p-dropdown [disabled]="MonthlyRadioButton != 'onDay'" formControlName="dayMontly" appendTo="body"
              optionLabel="day" [showTransitionOptions]="'0ms'" [options]="numberDaysMonth"
              appendTo="body"></p-dropdown>
            <label for="autocomplete">Día</label>
          </span>
        </div>

      </div>


    </div>
    <div *ngIf="repeat_type == 'MONTHLY'" class="col-12 md:col-6 mt-4">

    </div>


    <div *ngIf="repeat_type == 'MONTHLY'" class="col-12 md:col-6 mt-4 ">
      <div class="grid formgrid d-flex align-items-center">
        <div class="col-12 md:col-2 ">
          <p-radioButton name="MONTHLY" inputId="MontlhyDays" value="onThe" [ngModelOptions]="{standalone: true}"
            [(ngModel)]="MonthlyRadioButton"></p-radioButton>
          <label for="ingredient1" class="ml-2">Por</label>
        </div>
        <div class="col-12 md:col-5 mt-2">
          <span class="w-100 p-fluid p-float-label">
            <p-dropdown [disabled]="MonthlyRadioButton == 'onDay'" formControlName="DayWithLetterMontly" appendTo="body"
              optionLabel="label" [showTransitionOptions]="'0ms'" [options]="DaysWitLetter"
              appendTo="body"></p-dropdown>
          </span>
        </div>
        <div class="col-12 md:col-5 mt-2">

          <span class="w-100 p-fluid p-float-label">
            <p-dropdown [disabled]="MonthlyRadioButton == 'onDay'" formControlName="MonthWithLetterMontly"
              appendTo="body" optionLabel="label" [showTransitionOptions]="'0ms'" [options]="MontlhyDaysWithLetter"
              appendTo="body"></p-dropdown>
            <label for="autocomplete">Día</label>
          </span>
        </div>

      </div>


    </div>
    <!--END MONTLY-->


    <!--WEEKLY-->
    <div *ngIf="repeat_type == 'WEEKLY'" class="col-12 md:col-12 mt-4 ">
      <div class="horizontal-checkbox-container d-flex justify-content-center">
        <div *ngFor="let day of DaysOfWeek; let i = index" class="field-checkbox fw-bold  ">
          <p-checkbox [ngModelOptions]="{standalone: true}" [(ngModel)]="DaysOfWeek[i].value" name="group1"
            [value]="day.key" [binary]="true" [inputId]="day.key"></p-checkbox>
          <label class="fw-bold h6" [for]="day.key"> {{day.name}}</label>
        </div>
      </div>

    </div>



    <div *ngIf="repeat_type != 'PERSONAL'" class="col-12 md:col-12">
      <p-divider layout="horizontal" align="center">
        <span class="p-tag">Finalizar</span>
      </p-divider>
    </div>
    <div *ngIf="repeat_type != 'PERSONAL'" class="col-12 md:col-6 mt-4">
      <span class="w-100 p-fluid p-float-label">
        <p-autoComplete [multiple]="false" formControlName="end" (onSelect)="SelectRepeatTypeEnd($event)"
          [showEmptyMessage]="true" [suggestions]="repeatTypesEnd" appendTo="body" (completeMethod)="filterEnd($event)"
          field="label" [minLength]="1" [dropdown]="true">
        </p-autoComplete>
        <label for="autocomplete">Finalizar</label>
      </span>
    </div>

    <div *ngIf="repeat_type != 'PERSONAL'" class="col-12 md:col-6 mt-4">
      <span *ngIf="EndOptionSelected == 'COUNT'" class="w-100 p-fluid p-float-label">
        <input pInputText type="number" formControlName="endAfterValue">
        <label>enviado sesiones de SMS programadas</label>
      </span>
      <span *ngIf="EndOptionSelected == 'UNTIL'" class="w-100 p-fluid p-float-label">
        <p-calendar appendTo="body" formControlName="endDateValue"></p-calendar>
        <label>Selecciona fecha</label>
      </span>
    </div>
  </div>

</form>

<div class="text-center mt-3 f mb-4 ">
  <button [disabled]="Form.invalid" (click)="accept()" icon="pi pi-save" pButton pRipple type="submit" label="Guardar"
    class="p-button-raised btn-sm"></button>
</div>