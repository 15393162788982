<app-loading></app-loading>

<p-toast key="toast" position="top-right"></p-toast>

<p-confirmDialog #cd [style]="{width: '350px'}">
  <ng-template pTemplate="header">
    <h3>Confirmación</h3>
  </ng-template>

  <ng-template pTemplate="footer">
    <p-button size="small" styleClass="btn-sm" (click)="cd.accept()" icon="pi pi-check" label="confirmar"></p-button>
    <p-button size="small" (click)="cd.reject()" icon="pi pi-times" label="cancelar"
      styleClass="p-button-danger btn-sm"></p-button>
  </ng-template>
</p-confirmDialog>

<router-outlet></router-outlet>